// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$portal-primary: mat.define-palette(mat.$blue-palette);
$portal-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$portal-theme: mat.define-light-theme(
  (
    color: (
      primary: $portal-primary,
      accent: $portal-accent,
      warn: $portal-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($portal-theme);

/* You can add global styles to this file, and also import other style files */

// 1. Your custom variables and variable overwrites.
$color-primary: #f27f21;
$global-primary-background: #f27f21;
$global-link-color: #f27f21;
$global-font-family: "Lato", sans-serif;
$border-color-default: #e5e5e5;
$button-text-transform: unset;

// 2. Import default variables and available mixins.
@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
@mixin hook-card() {
  color: #000;
}
@mixin hook-button() {
  animation: all 0.2s;
  height: 50px;
  border-radius: 50px;
}
@mixin hook-button-hover() {
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.08);
}
@mixin hook-button-default() {
  background: #f2faff;
  border: 1px solid #de6343;
  color: #de6343 !important;
}
@mixin hook-button-primary() {
  background: linear-gradient(90deg, #de6242 0%, #e2844a 100%);
}
@mixin hook-button-default-hover() {
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.08);
}
@mixin hook-button-primary-hover() {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
}
// 4. Import UIkit.
@import "uikit/src/scss/uikit-theme.scss";
@import "./helpers";

:root {
  --color-primary: #f27f21;
  --color-text: #282828;
  --color-white: #ffffff;
  --color-light-blue: #a1c7e2;
  --color-bg-light-blue: #f2f5f8;
  --navbar-min-height: 50px;
  --body-min-height-with-navbar: calc(100vh - var(--navbar-min-height));
}

html {
  scroll-behavior: smooth;
}

.rb-text-primary {
  color: var(--color-primary) !important;
}

.rb-m-auto {
  margin: auto !important;
}

.rb-bg-white {
  background-color: white !important;
}

.rb-h1 {
  font-family: PT Serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
}

.uk-input,
.uk-select,
.rb-autocomplete input{
  border-radius: 30px !important;
  padding: 0 20px !important;
  height: 50px !important;
}

.rb-autocomplete{
  .autocomplete-container{
      box-shadow: none !important;
  }
  input{
      color: #666 !important;
      border: 1px solid #e5e5e5 !important;
      transition: 0.2s ease-in-out !important;
  }
  input:focus {
    outline: none !important;
    background-color: #fff !important;
    color: #666 !important;
    border-color: #f27f21 !important;
  }
}

.uk-form-label {
  color: #9c9c9c;
}

.uk-box-shadow-none {
  box-shadow: unset;
}

.rb-lr-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.rb-t-40 {
  margin-top: -40px;
}

.rb-navbar {
  background: var(--color-white);
  // box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid #dfdfdf;
  .uk-navbar-nav > li > a,
  .uk-navbar-item,
  .uk-navbar-toggle {
    min-height: var(--navbar-min-height);
    color: var(--color-text);
    text-transform: capitalize;
  }
  .rb-navbar-logo {
    height: 40px;
    width: auto;
  }
}

.rb-portal-master {
  position: relative;
  .rb-portal-master-aside {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: var(--body-min-height-with-navbar);
    width: 60px;
    position: fixed;
    top: 50px;
    left: 0px;
    z-index: 4;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.08);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    transition: all 0.2s;
    ul.rb-portal-master-aside-menu {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 8px 0px;
      overflow-y: auto;
      overflow-x: hidden;
      li {
        display: flex;
        justify-content: center;
        padding: 0px 14px;
        .rb-master-aside-nav-icon {
          display: flex;
          width: 46px;
          height: 46px;
          border-radius: 5px;
          font-size: 28px;
          justify-content: center;
          path {
            fill: #9c9c9c;
          }
        }
        .rb-master-aside-nav-title {
          display: none;
          padding-left: 12px;
        }
        &.uk-active {
          .rb-master-aside-nav-icon {
            background: rgba(242, 127, 33, 0.1);
            path {
              fill: #f27f21;
            }
          }
        }
      }
    }
    .rb-portal-master-aside-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-x: hidden;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 58px;
        padding: 14px;
        text-decoration: none;
        color: $global-color;
        .rb-master-aside-nav-icon {
          display: flex;
          border-radius: 5px;
          font-size: 28px;
          justify-content: center;
          transition: all 500ms;
          path {
            fill: #000;
          }
        }
        .rb-master-aside-nav-title {
          display: none;
          padding-left: 12px;
          opacity: 0.6;
        }
        &:hover {
          .rb-master-aside-nav-title {
            opacity: 1;
          }
        }
      }
    }
  }
  .rb-portal-master-content {
    background-color: #fff;
    padding-left: 60px;
    min-height: var(--body-min-height-with-navbar);
    position: relative;
    transition: all 0.2s;
  }
  &.rb-open {
    .rb-portal-master-aside {
      width: 240px;
      ul.rb-portal-master-aside-menu {
        li {
          justify-content: unset;
          .rb-master-aside-nav-icon {
            display: inline-flex;
          }
          .rb-master-aside-nav-title {
            display: inline-block;
          }
        }
      }
      .rb-portal-master-aside-footer {
        a {
          justify-content: unset;
          .rb-master-aside-nav-icon {
            display: inline-flex;
            transform: rotate(-180deg);
          }
          .rb-master-aside-nav-title {
            display: inline-block;
          }
        }
      }
    }
    .rb-portal-master-content {
      padding-left: 240px;
    }
  }
}

.rb-jumbo {
  position: relative;
  --key-offset: calc((100vw - 1200px) / 2);
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: calc(var(--key-offset) + 40px + 15px);
    background-color: var(--color-light-blue);
  }
  .rb-jumbo-body {
    position: relative;
    padding: 0px;
    @include rb-screen-max-size-handler("-md") {
      padding: 0px;
    }
    .rb-jumbo-asset {
      position: relative;
      min-height: var(--body-min-height-with-navbar);
      background-color: var(--color-light-blue);
      overflow: hidden;
      @include rb-screen-max-size-handler("-md") {
        min-height: unset;
      }
      .rb-jumbo-key-image {
        padding-left: calc(((100vw - 1200px) / 5));
        @include rb-screen-max-size-handler("-md") {
          padding-left: 0px;
        }
        // max-width: unset;
        // max-height: 100%;
        // width: calc(100% + (var(--key-offset) / 2));
        // margin-left: calc(0% - (var(--key-offset) / 2) - 20px);
      }
    }
    .rb-jumbo-container {
      // width: calc((99% * (2 / 3)) + ((100vw - 1200px) / 2) + 0px);
      // margin-right: calc(0% - calc(calc(100vw - 1200px) / 2) + 8px);
      // @include rb-screen-max-size-handler("-lg") {
      //   width: calc((99% * (2 / 3)) + ((100vw - 1200px) / 2) + 0px);
      //   margin-right: calc(0% - calc(calc(100vw - 1200px) / 2) + 8px);
      // }
      // @include rb-screen-max-size-handler("-md") {
      //   width: calc(99% * (3 / 5));
      //   margin-right: unset;
      // }
      // @include rb-screen-max-size-handler("-sm") {
      //   width: unset;
      //   margin-right: unset;
      // }
      .rb-jumbo-content {
        max-width: 624px;
        margin: auto;
      }
    }
  }
}

.uk-accordion {
  li {
    margin: 0px !important;
    padding: 20px 16px;
    border-top: 1px solid #ccc;
    &:first-child {
      border-top: 0px solid #ccc;
    }
    .uk-accordion-title {
      font-weight: 700;
      &::before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMS4wMzAxNSAyLjgyMzIxQzAuNDcwNjE5IDIuMjkzNjUgMC40NzA2MTkgMS40MDI4NCAxLjAzMDE1IDAuODczMjg4QzEuNTQ3NzcgMC4zODM0MDIgMi4zNTc5OCAwLjM4MzQwMyAyLjg3NTYgMC44NzMyODhMNi41IDQuMzAzNTFMMTAuMTI0NCAwLjg3MzI4OEMxMC42NDIgMC4zODM0MDMgMTEuNDUyMiAwLjM4MzQwMyAxMS45Njk4IDAuODczMjg4QzEyLjUyOTQgMS40MDI4NCAxMi41Mjk0IDIuMjkzNjUgMTEuOTY5OCAyLjgyMzIxTDYuNSA4TDEuMDMwMTUgMi44MjMyMVoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=");
      }
    }
    &.uk-open {
      background-color: white;
      .uk-accordion-title {
        font-weight: 700;
        &::before {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEuOTY5OCA1LjE3Njc5QzEyLjUyOTQgNS43MDYzNSAxMi41Mjk0IDYuNTk3MTYgMTEuOTY5OCA3LjEyNjcxQzExLjQ1MjIgNy42MTY2IDEwLjY0MiA3LjYxNjYgMTAuMTI0NCA3LjEyNjcxTDYuNSAzLjY5NjQ5TDIuODc1NiA3LjEyNjcxQzIuMzU3OTggNy42MTY2IDEuNTQ3NzcgNy42MTY2IDEuMDMwMTUgNy4xMjY3MUMwLjQ3MDYxOSA2LjU5NzE2IDAuNDcwNjIgNS43MDYzNSAxLjAzMDE1IDUuMTc2NzlMNi41IDBMMTEuOTY5OCA1LjE3Njc5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==");
        }
      }
      .uk-accordion-content {
        margin: 0px !important;
      }
    }
  }
}

.rb-background-blue {
  background-color: var(--color-bg-light-blue);
}

.rb-workpoint {
  & > div > .rb-workpoint-card {
    position: relative;
    .rb-workpoint-step {
      position: relative;
      width: 24px;
      font-size: 14px;
      height: 24px;
      border-radius: 50px;
      z-index: 3;
      background-color: #0066af;
      color: white !important;
    }
    .uk-text-muted {
      color: #9c9c9c !important;
    }
    &:before {
      content: "";
      width: 100%;
      border-top: 1px dashed #282828;
      top: 38px;
      position: absolute;
      z-index: 2;
      margin-left: 16px;
    }
  }
  & > div:last-child > .rb-workpoint-card::before {
    content: unset;
  }
}
.rb-testimonial-slider {
  padding: 0px;
  margin-right: calc(0% - ((100vw - 1200px) / 2) + 8px);
  padding-right: calc(((100vw - 1200px) / 2) + 0px);
  @include rb-screen-max-size-handler("-lg") {
    padding-right: calc(((100vw - 1200px) / 2) + 0px);
    margin-right: calc(0% - ((100vw - 1200px) / 2) + 7px);
  }
  @include rb-screen-max-size-handler("-md") {
    padding: 0px 40px;
    padding-right: 0px;
    margin-right: unset;
  }
  @include rb-screen-max-size-handler("-sm") {
    padding-right: 15px;
  }

  .rb-testimonial {
    & > div > .rb-testimonial-card {
      position: relative;
      border-radius: 8px;
      width: 400px;
      overflow: hidden;
      .rb-testimonial-step {
        position: relative;
        width: 24px;
        font-size: 14px;
        height: 24px;
        border-radius: 50px;
        z-index: 3;
        background-color: #0066af;
        color: white !important;
      }
      .uk-text-muted {
        color: #9c9c9c !important;
      }
      .uk-text-primary {
        color: #0066af !important;
      }
    }
  }
}

.rb-footer-grid-menu {
  .rb-footer-grid-menu-title {
    color: #9c9c9c;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: unset;
  }
  .rb-footer-grid-submenu {
    margin: 0px;
    padding: 0px;
    .rb-footer-grid-submenu-item {
      list-style: none;
      & > a {
        color: #282828;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
      }
    }
  }
  &.rb-footer-grid-inline-menu {
    .rb-footer-grid-submenu {
      .rb-footer-grid-submenu-item {
        display: inline-block;
        &:not(:last-child):after {
          content: "|";
          padding: 0px 8px;
          font-weight: 700;
        }
      }
    }
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.error{
  color: red;
  font-size: 10px;
}
