$rb-keyword: "rb" !default;
$rb-class-slug-padding-list: "p" "p-t" "p-b" "p-l" "p-r" "p-v" "p-h";
$rb-class-slug-margin-list: "m" "m-t" "m-b" "m-l" "m-r" "m-v" "m-h";
$rb-class-slug-anchors-list: "" "-t" "-b" "-l" "-r" "-v" "-h";
$rb-class-slug-screen-size-list: "" "-xs" "-sm" "-md" "-lg";
$rb-class-slug-gutters-list: "padding" "margin";
$rb-palate-shades: 0 50 100 200 300 400 500 600 700;
$rb-font-weight: 100 200 300 400 500 600 700 800 900;
$rb-breakpoint-sm: 640px !default;
$rb-breakpoint-md: 960px !default;
$rb-breakpoint-lg: 1200px !default;
$rb-breakpoint-xl: 1600px !default;
$rb-breakpoint-max-xs: ($rb-breakpoint-sm - 1) !default;
$rb-breakpoint-max-sm: ($rb-breakpoint-md - 1) !default;
$rb-breakpoint-max-md: ($rb-breakpoint-lg - 1) !default;
$rb-breakpoint-max-lg: ($rb-breakpoint-xl - 1) !default;

@mixin rb-screen-size-handler($screen) {
  @if ($screen== "") {
    @media (min-width: $rb-breakpoint-max-lg + 1) {
      @content;
    }
  } @else if ($screen== "-lg") {
    @media (max-width: $rb-breakpoint-max-lg) and (min-width: $rb-breakpoint-max-md + 1) {
      @content;
    }
  } @else if ($screen== "-md") {
    @media (max-width: $rb-breakpoint-max-md) and (min-width: $rb-breakpoint-max-sm + 1) {
      @content;
    }
  } @else if ($screen== "-sm") {
    @media (max-width: $rb-breakpoint-max-sm) and (min-width: $rb-breakpoint-max-xs + 1) {
      @content;
    }
  } @else if ($screen== "-xs") {
    @media (max-width: $rb-breakpoint-max-xs) {
      @content;
    }
  }
}

@mixin rb-screen-max-size-handler($screen) {
  @if ($screen== "") {
    @content;
  } @else if ($screen== "-lg") {
    @media (max-width: $rb-breakpoint-max-lg) {
      @content;
    }
  } @else if ($screen== "-md") {
    @media (max-width: $rb-breakpoint-max-md) {
      @content;
    }
  } @else if ($screen== "-sm") {
    @media (max-width: $rb-breakpoint-max-sm) {
      @content;
    }
  } @else if ($screen== "-xs") {
    @media (max-width: $rb-breakpoint-max-xs) {
      @content;
    }
  }
}

@mixin rb-anchor-handler($anchor-of, $anchor, $param) {
  @if ($anchor== "") {
    #{$anchor-of}: $param !important;
  } @else if ($anchor== "-t") {
    #{$anchor-of}-top: $param !important;
  } @else if ($anchor== "-b") {
    #{$anchor-of}-bottom: $param !important;
  } @else if ($anchor== "-l") {
    #{$anchor-of}-left: $param !important;
  } @else if ($anchor== "-r") {
    #{$anchor-of}-right: $param !important;
  } @else if ($anchor== "-v") {
    #{$anchor-of}-top: $param !important;
    #{$anchor-of}-bottom: $param !important;
  } @else if ($anchor== "-h") {
    #{$anchor-of}-left: $param !important;
    #{$anchor-of}-right: $param !important;
  }
}

@mixin rb-gutter-anchor-handler($anchor-of, $anchor, $i) {
  @include rb-anchor-handler($anchor-of, $anchor, 0px + $i);
}

@mixin rb-gutter-screen-size-handler($screen, $gutter, $anchor, $i) {
  $anchor-of: "";

  @if ($gutter== "margin") {
    $anchor-of: "m";
  } @else if ($gutter== "padding") {
    $anchor-of: "p";
  }

  @if ($screen== "") {
    .#{$rb-keyword}-#{$anchor-of}#{$anchor}-#{$i} {
      @include rb-gutter-anchor-handler($gutter, $anchor, $i);
    }
  } @else if ($screen== "-lg") {
    @media (max-width: $rb-breakpoint-max-lg) {
      .#{$rb-keyword}-#{$anchor-of}#{$anchor}#{$screen}-#{$i} {
        @include rb-gutter-anchor-handler($gutter, $anchor, $i);
      }
    }
  } @else if ($screen== "-md") {
    @media (max-width: $rb-breakpoint-max-md) {
      .#{$rb-keyword}-#{$anchor-of}#{$anchor}#{$screen}-#{$i} {
        @include rb-gutter-anchor-handler($gutter, $anchor, $i);
      }
    }
  } @else if ($screen== "-sm") {
    @media (max-width: $rb-breakpoint-max-sm) {
      .#{$rb-keyword}-#{$anchor-of}#{$anchor}#{$screen}-#{$i} {
        @include rb-gutter-anchor-handler($gutter, $anchor, $i);
      }
    }
  } @else if ($screen== "-xs") {
    @media (max-width: $rb-breakpoint-max-xs) {
      .#{$rb-keyword}-#{$anchor-of}#{$anchor}#{$screen}-#{$i} {
        @include rb-gutter-anchor-handler($gutter, $anchor, $i);
      }
    }
  }
}

@each $screen in $rb-class-slug-screen-size-list {
  /**
   * General Gutter Param iteration
   */
  @each $anchor in $rb-class-slug-anchors-list {
    @for $i from 0 through 200 {
      @each $gutter in $rb-class-slug-gutters-list {
        @include rb-gutter-screen-size-handler($screen, $gutter, $anchor, $i);
      }
    }
  }

  /**
   * General Radius Param iteration
   */
  @for $i from 0 through 50 {
    @include rb-screen-max-size-handler($screen) {
      .#{$rb-keyword}-br#{$screen}-#{$i} {
        border-radius: 0px + $i !important;
      }
    }
  }

  /**
   * General Border Param iteration
   */
  @each $anchor in $rb-class-slug-anchors-list {
    @for $i from 0 through 4 {
      @include rb-screen-max-size-handler($screen) {
        .#{$rb-keyword}-b#{$anchor}#{$screen}-#{$i} {
          @include rb-anchor-handler(
            "border",
            $anchor,
            0px + $i solid $border-color-default
          );
        }
      }
    }
  }

  /**
   * General font size iteration
   */
  @for $i from 6 through 160 {
    @include rb-screen-max-size-handler($screen) {
      .#{$rb-keyword}-font-size#{$screen}-#{$i} {
        font-size: 0px + $i !important;
      }
    }

    @include rb-screen-max-size-handler($screen) {
      .#{$rb-keyword}-lh#{$screen}-#{$i} {
        line-height: 0px + $i !important;
      }
    }
  }

  /**
   * General font size iteration
   */
  //    @each $anchor in '50px' {
  @include rb-screen-max-size-handler($screen) {
    .#{$rb-keyword}-reset#{$screen}-height {
      min-height: unset !important;
      max-height: unset !important;
    }

    .#{$rb-keyword}-full#{$screen}-height {
      height: 100% !important;
      min-height: 100% !important;
      max-height: 100% !important;
    }

    .#{$rb-keyword}-reset#{$screen}-width {
      min-width: unset !important;
      max-width: unset !important;
    }

    .#{$rb-keyword}-full#{$screen}-width {
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
    }
  }

  // }
}

@each $weight in $rb-font-weight {
  .#{$rb-keyword}-fw-#{$weight} {
    font-weight: $weight !important;
  }
}

/**
   * General max-height Param iteration
   */

@for $i from 10 through 100 {
  .#{$rb-keyword}-mxh-#{$i} {
    max-height: 0px + $i !important;
  }
}

@for $i from 10 through 1000 {
  $a: $i * 2;

  .#{$rb-keyword}-mxw-#{$a} {
    max-width: 0px + $a !important;
  }

  $a: $i * 2;

  .#{$rb-keyword}-mnw-#{$a} {
    min-width: 0px + $a !important;
  }
}

/**
   * General min-height Param iteration
   */

@for $i from 10 through 1000 {
  $a: $i * 2;

  .#{$rb-keyword}-mxh-#{$a} {
    max-height: 0px + $a !important;
  }

  $a: $i * 2;

  .#{$rb-keyword}-mnh-#{$a} {
    min-height: 0px + $a !important;
  }
}

// @for $i from 10 through 100 {
//     .#{$rb-keyword}-mnh-#{$i} {
//         min-height: 0px + $i !important;
//     }
// }
